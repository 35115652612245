
.mycard{
    
    background-color: white;
  
    border-radius: 10px;
    .Icon{
      background-color: green;
      color: white;
     
      border-radius: 10px;
        // position: absolute;
        // top: 20px;
        // transform: translate(10% , 10%);
    }
}