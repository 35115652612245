// Here you can add other styles
.over{

    cursor: pointer;
}

.parent{

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.bg-chart{
    background-color: white;


}

.chart-heigth{
    max-height: 200px;
}